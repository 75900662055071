import { DocumentationFile } from "@gc/models";
import { ArrayElement } from "@gc/types";

export interface ManualDatabaseReference {
  originDatabase: string;
  co2Factor: number;
}

export interface ExternalDatabaseReference {
  co2eDatabaseEntryId: string;
  scaleFactor: number;
}

export interface Co2TypeVersionInput {
  id?: string;
  validFrom: string;
  validUntil: string;
  locked: boolean;
  kgEquivalent: number;
  documentation: string;
  documentationFiles?: DocumentationFile[];
  addedDocumentationFilesIndexes?: number[];
  deletedDocumentationFileIds?: string[];
  externalDatabaseReference?: ExternalDatabaseReference;
  manualDatabaseReference?: ManualDatabaseReference;
}

export interface Co2eDatabase {
  uuid: string;
  name: string;
}

export interface ExternalCo2eDatabaseEntry {
  id: string;
  co2eDatabase: Co2eDatabase;
  geography: string;
  displayName: string;
  co2Equivalent: number;
  unit: string;
  scaleFactor: number;
}

export interface Co2TypeVersionOutput {
  id: string;
  validFrom: string;
  validUntil: string;
  locked: boolean;
  co2Factor: number;
  kgEquivalent: number;
  originDatabase: string;
  documentation: string;
  documentationFiles?: DocumentationFile[];
  co2eDatabaseEntry?: ExternalCo2eDatabaseEntry;
}

export enum QuantityType {
  DEFAULT = "DEFAULT",
  DISTANCE_ROAD = "DISTANCE_ROAD",
  DISTANCE_RAIL = "DISTANCE_RAIL",
  DISTANCE_SEA = "DISTANCE_SEA",
  DISTANCE_AIR = "DISTANCE_AIR",
}

export enum Co2TypeStatus {
  NOT_DEFINED = "NOT_DEFINED",
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  ON_HOLD = "ON_HOLD",
  COMPLETED = "COMPLETED",
  NOT_IN_USE = "NOT_IN_USE",
  DEACTIVATED = "DEACTIVATED",
}

export enum Co2TypeStatusFilter {
  ALL_EXCEPT_DEACTIVATED = "ALL_EXCEPT_DEACTIVATED",
  NOT_DEFINED = "NOT_DEFINED",
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  ON_HOLD = "ON_HOLD",
  COMPLETED = "COMPLETED",
  NOT_IN_USE = "NOT_IN_USE",
  DEACTIVATED = "DEACTIVATED",
}

export const CO2TYPE_STATUS_LABELS: { [key in Co2TypeStatusFilter]: string } = {
  [Co2TypeStatus.NOT_DEFINED]: "common_enum_not_defined",
  [Co2TypeStatus.NOT_STARTED]: "common_enum_not_started",
  [Co2TypeStatus.IN_PROGRESS]: "common_enum_in_progress",
  [Co2TypeStatus.ON_HOLD]: "common_enum_on_hold",
  [Co2TypeStatus.COMPLETED]: "common_enum_completed",
  [Co2TypeStatus.NOT_IN_USE]: "common_enum_not_in_use",
  [Co2TypeStatus.DEACTIVATED]: "common_enum_deactivated",
  [Co2TypeStatusFilter.ALL_EXCEPT_DEACTIVATED]: "common_enum_all_except_deactivated",
};

export const CO2TYPE_STATUS_COLORS: { [key in Co2TypeStatusFilter]: string } = {
  [Co2TypeStatus.NOT_DEFINED]: "#F1F5F9",
  [Co2TypeStatus.NOT_STARTED]: "#E8A100",
  [Co2TypeStatus.IN_PROGRESS]: "#009DC3",
  [Co2TypeStatus.ON_HOLD]: "#DB0B43",
  [Co2TypeStatus.COMPLETED]: "#43B566",
  [Co2TypeStatus.NOT_IN_USE]: "#94A3B8",
  [Co2TypeStatus.DEACTIVATED]: "#FF0000",
  [Co2TypeStatusFilter.ALL_EXCEPT_DEACTIVATED]: "#94A3B8",
};

export enum Co2CategoryActivationStatus {
  ACTIVATED = "ACTIVATED",
  DEACTIVATED = "DEACTIVATED",
}

export interface ScopeInfo {
  id: string;
  name: string;
  description: string;
}

export interface UICategoryInfo {
  id: string;
  name: string;
}

export interface LocalizedText {
  de: string;
  en: string;
}

export interface Co2Category {
  id: string;
  name: LocalizedText;
  scope: ScopeInfo;
  createdAt: number;
  uiCategory: UICategoryInfo;
  activationStatus: Co2CategoryActivationStatus;
}

export interface Co2CategorySearchModel {
  id: string;
  name: string;
  scope: string;
  createdAt: number;
  activationStatus: Co2CategoryActivationStatus;
}

export interface CategoryInfo {
  id: string;
  name: string;
  activationStatus: Co2CategoryActivationStatus;
  ghgScope: ScopeInfo;
}

export enum Co2TypeBucketScope {
  GLOBAL = "GLOBAL",
  COMPANY = "COMPANY",
  SHARED = "SHARED",
}

export interface Co2TypeBucket {
  id: string;
  name: string;
  scope: Co2TypeBucketScope;
  companies: { id: string; name: string }[];
  companyGroups: { id: string; name: string; companies: ArrayElement<Co2TypeBucket["companies"]>[] }[];
}

// Base Co2Type interface
export interface BaseCo2Type {
  id: string;
  name: LocalizedText;
  bucket: Pick<Co2TypeBucket, "id" | "name" | "scope">;
  geography: string;
  quantityType: QuantityType;
  scope: ScopeInfo;
  category: CategoryInfo;
  unit: {
    id: string;
    name: string;
    description?: string;
    symbols: string[];
  };
  status: Co2TypeStatus;
}

// Input Co2Type
export interface Co2Type extends BaseCo2Type {
  versions: Co2TypeVersionInput[];
}

// Output Co2Type
export interface Co2TypeOutput extends BaseCo2Type {
  versions: Co2TypeVersionOutput[];
}

export interface Co2TypeSearch {
  id: string;
  name: string;
  bucket: { id: string; name: string; scope: Co2TypeBucketScope };
  categoryId: string;
  categoryName: string;
  categoryActivationStatus: Co2CategoryActivationStatus;
  scope: string;
  unit: string;
  versionsCount: number;
  currentCo2Factor: number;
  currentKgEquivalent?: number;
  createdAt: number;
  quantityType?: QuantityType;
  geography?: string;
  // TODO: Is this really the Co2TypeVersionInput? Shouldn't it be Output?
  versions?: Co2TypeVersionInput[];
  externalIds?: string[];
  status?: Co2TypeStatus;
}

export interface Co2TypeUsage {
  company: {
    id: string;
    name: string;
  };
  taggedCount: number; // number of times the co2 type is tagged
  unitBookingCount: number; // number of unit bookings connected to the co2 type
  supplierCount: number; // number of suppliers having at least 1 transport route which uses the co2 type
  financialModelCount: number; // number of financial models having at least 1 financial model item which uses the co2 type
  commuterSurveyCount: number; // number of commuter surveys connected to the co2 type
}
